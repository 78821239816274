import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps';
import { AiOutlineMail, AiOutlineFieldTime } from "react-icons/ai";
import { FcHome, FcPhone } from "react-icons/fc";
import { Dane } from '../LocalData';
import './Contact.css'

export class Contact extends Component {

    static displayName = Contact.name;

    render() {
        return (
            <section id="contact">
                <Container>
                    <div className="content">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="text-center">Kontakt</h1>
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2" />
                            <div className="col-sm-4">
                                <h2 className="text-center">{Dane.Nazwa}</h2>
                                <h3 className="text-center">Mateusz Dłutek</h3>
                                {/* <h4 className="text-center"><FcHome /> {Dane.Adres} <br /> {Dane.Kod} {Dane.Miasto}</h4> */}
                                <br />
                                <h4 className="text-center"> <AiOutlineMail /> {Dane.Email}</h4>
                                <h4 className="text-center"> <FcPhone /> {Dane.Telefon}</h4>
                                <h4 className="text-center"> <AiOutlineFieldTime /> 9:00-17:00</h4>
                            </div>
                            <div className="col-sm-4">
                                <h3 className="text-center">Informacje</h3>
                                <table>
                                    <tr>
                                        <td className="text-right">NIP:</td>
                                        <td>{Dane.NIP}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">REGON:</td>
                                        <td>{Dane.REGON}</td>
                                    </tr>
                                    {/* <tr>
                                        <td className="text-right">NR KONTA:</td>
                                        <td>{Dane.NrKonta}</td>
                                    </tr> */}
                                </table>
                                {/* <h4 className="text-left"> <b>NIP:</b> {Dane.NIP}</h4>
                                <h4 className="text-left"> <b>REGON:</b> {Dane.REGON} </h4>
                                <h4 className="text-left"> <b>NR KONTA:</b> {Dane.NrKonta} </h4> */}
                            </div>
                            <div className="col-sm-2" />
                        </div>
                    </div>

                </Container>

                <div className="row map" >
                    <WrappedMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}

                        loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
                        containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                        mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                    />
                </div>

            </section>
        );
    }
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

function Map() {
    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: 50.278439, lng: 19.134125 }}
        />
    );
}

export default Contact;