import React, { Component } from 'react';
import './SoftMobile.css'

export class SoftMobile extends Component {

    static displayName = SoftMobile.name;

    render() {
        return (
            <section id="mobile">
                <div className="mobile-banner"></div>
                <div className="mobile-content">
                    <div className="mobile-content-caption">
                        <h1>Aplikacje mobilne</h1>
                        <hr className="white" />
                        <h2>Zarządzanie i kontrola firmy na wyciągnięcie ręki.</h2><h2> Dedykowane aplikacje mobilne na platformy Android lub iOS pozwalają w łatwy i wygodny sposób zarządzać firmą z urządzeń przenośnych takich jak: tablety lub smartfony. </h2>
                    </div>
                </div>

            </section>
        );
    }
}