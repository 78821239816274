import React, { Component } from 'react';

import Image from 'react-bootstrap/Image'
import { Container } from 'reactstrap';
import './About.css'

export class About extends Component {

    static displayName = About.name;

    render() {
        return (

            <section id="about">
                <Container>
                    <div>
                        <h1>O firmie</h1>
                        <hr />

                        <h2>MD-Dev to firma specjalizująca się w wytwarzaniu dedykowanych aplikacji: desktopowych, webowych oraz mobilnych, dostosowanych do potrzeb Twojej firmy.</h2>

                        <h2><span className="bold">Upraszczanie schematów pracy, oszczędność czasu, dokładność, bezbłędność</span> to tylko niektóre korzyści związane z wdrożeniem dedykowanych systemów informatycznych.</h2>
                        <h2>Ponadto gromadzenie i zapis informacji w bazach danych pozwala na stałe monitorowanie i kontrolę wyników produkcyjnych.</h2>

                        <h2><span className="underline">Sukces Twojej firmy zależy od przepływu informacji na każdym szczeblu jej działania!</span></h2>

                        <h2 className="end"><span className="bold">Zapraszamy do zapoznania się z ofertą oraz do współpracy.</span></h2>

                    </div>
                </Container>
            </section>
        );
    }
}

export default About;