import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../Images/md-dev-logo.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Dane } from '../LocalData';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    closeNavbar() {
        if (this.state.collapsed !== true) {
            this.toggleNavbar();
        }
    }

    render() {
        return (
            <header>
                <Navbar className="navbar fixed-top navbar-expand-lg navbar-toggleable-lg navbar-light bg-light border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img
                                src={logo}
                                width="40"
                                height="40"
                                className="d-inline-block align-top"
                                alt="MD-Dev-logo"
                            />
                            <span className="logo-text">{Dane.Nazwa}</span>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#home' onClick={this.closeNavbar}>Strona główna</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#about' onClick={this.closeNavbar}>O firmie</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#pc' onClick={this.closeNavbar}>Desktop</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#web' onClick={this.closeNavbar}>Web</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#mobile' onClick={this.closeNavbar}>Mobile</AnchorLink>
                                </NavItem>
                                <NavItem>
                                    <AnchorLink offset='65' className="text-dark nav-link" href='#contact' onClick={this.closeNavbar}>Kontakt</AnchorLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
