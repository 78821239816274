import React, { Component } from 'react';

import { FaFacebook, FaTwitter, FaDribbble, FaLinkedinIn, FaMobileAlt } from "react-icons/fa";
import { AiOutlineMail, AiOutlineHome } from "react-icons/ai";

import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../Images/md-dev-logo.png'
import './Footer.css';

import { Dane } from '../LocalData';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            <h6>O firmie</h6>
                            <p className="text-justify">Dedykowane aplikacje dopasowane do potrzeb Twojej firmy.</p>
                        </div>
                        <div className="col-xs-4 col-md-3">
                            <h6>Partnerzy</h6>
                            <ul className="footer-links">
                                <li><a href='http://virta.com.pl' target='_blank'>Virta.com.pl</a></li>
                                <li><a href='http://geomax.info.pl/' target='_blank'>Geomax.info.pl</a></li>
                                <li> &nbsp;</li>
                                <li><AnchorLink offset='65' href='#contact'>Zostań partnerem</AnchorLink></li>
                            </ul>
                        </div>
                        <div className="col-xs-4 col-md-3">
                            <h6>Nawigacja</h6>
                            <ul className="footer-links">
                                <li><AnchorLink offset='65' href='#home'>Strona główna</AnchorLink></li>
                                <li><AnchorLink offset='65' href='#about'>O firmie</AnchorLink></li>
                                <li><AnchorLink offset='65' href='#pc'>Desktop</AnchorLink></li>
                                <li><AnchorLink offset='65' href='#web'>Web</AnchorLink></li>
                                <li><AnchorLink offset='65' href='#mobile'>Mobile</AnchorLink></li>
                                <li><AnchorLink offset='65' href='#contact'>Kontakt</AnchorLink></li>
                            </ul>
                        </div>
                        <div className="col-xs-4 col-md-3">
                            <h6>Kontakt</h6>
                            <ul className="footer-links">
                                <li><span>{Dane.Nazwa} {Dane.Wlasciciel}</span></li>
                                <li><span> <FaMobileAlt /> {Dane.Telefon}</span></li>
                                <li><span> <AiOutlineMail /> {Dane.Email}</span></li>
                                <li> &nbsp; </li>
                                {/* <li><span> <AiOutlineHome /> {Dane.Adres} </span></li>
                                <li>{Dane.Kod} {Dane.Miasto} </li> */}
                            </ul>
                        </div>

                    </div>
                    <hr />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">Copyright &copy; 2020-{GetCurrentYear()} All Rights Reserved by <a href="/"> MD-Dev.net.pl </a></p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <li><a className="facebook" ><FaFacebook /><i className="fa fa-facebook"></i></a></li>
                                <li><a className="twitter"  ><FaTwitter /><i className="fa fa-twitter"></i></a></li>
                                <li><a className="dribbble" ><FaDribbble /><i className="fa fa-dribbble"></i></a></li>
                                <li><a className="linkedin" ><FaLinkedinIn /><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}

export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export function GetCurrentYear() {
    let newDate = new Date();
    let year = newDate.getFullYear();
    return year;
}