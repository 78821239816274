import React, { Component } from 'react';

export const Dane = {
    Nazwa: 'MD-Dev',
    Wlasciciel: 'Mateusz Dłutek',
    Adres: 'Bohaterów Monte Cassino',
    Miasto: 'Sosnowiec',
    Kod: '41-219',
    Telefon: '+48 505 381 932',
    Email: 'biuro@md-dev.net.pl',
    NIP: '644-355-72-07',
    REGON: '387487628',
    NrKonta: '96 1140 2004 0000 3802 8081 2273'
}