import React, { Component } from 'react';
import './SoftWeb.css'

export class SoftWeb extends Component {

    static displayName = SoftWeb.name;

    render() {
        return (
            <section id="web">
                <div className="web-content">
                    <div className="web-content-caption">
                        <h1>Aplikacje Webowe</h1>
                        <hr className="white" />
                        <h2>Aplikacje działające w przeglądarkach internetowych pozwalają na szybki i swobodny dostęp do informacji o Twojej firmie. Nie wymagają instalacji na komputerach, a jedyne co potrzebują, to dostęp do internetu.</h2>
                        <h2>Aplikacje typu intranet to systemy webowe przeznaczone do wewnętrznego zarządzania firmą. Spójny system komunikacji wewnątrz firmy przyczynia się do wzrostu efektywności pracy.</h2>
                    </div>
                </div>
                <div className="web-banner"></div>
            </section>
        );
    }
}