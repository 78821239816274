import React, { Component } from 'react';
import './SoftPc.css'

export class SoftPc extends Component {

    static displayName = SoftPc.name;

    render() {
        return (

            <section id="pc" >
                <div className="pc-banner"></div>
                <div className="pc-content">
                    <div className="pc-content-caption">
                        <h1>Aplikacje Desktopowe</h1>
                        <hr className="white" />
                        <h2>
                            Aplikacje desktopowe typu: WinForms, WPF, UWP to programy instalowane i uruchamiane bezpośrednio na urządzeniach z systemami operacyjnymi z rodziny Microsoft Windows (XP, Vista, 7, 8, 10).
                        </h2>
                        <h2>
                            Znajdują zastosowanie przy obsłudze zewnętrznych urządzeń takich jak: skanery kodów kreskowych, czytniki kart magnetycznych, drukarki etykiet oraz innych urządzeń podłączanych bezpośrednio do komputerów.
                        </h2>
                    </div>
                </div>
            </section>
        );
    }

}