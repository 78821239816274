import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { motion } from "framer-motion"
import { Dane } from '../LocalData';
import './Banner.css'

export class Banner extends Component {

    static displayName = Banner.name;

    render() {
        return (
            <section id="home" className="bg-img">
                <div className="bg-opacity">
                    <Container>
                        <div className="bg-text">
                            <motion.h1
                                initial={{ y: -200, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{delay: 0.3, duration: 0.5}}
                            >
                                {Dane.Nazwa}
                            </motion.h1>
                            <hr className="white" />
                            <motion.h2
                                initial={{y: 200, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                transition={{delay: 0.6, duration: 0.5}}
                            >
                                Oprogramowania dostosowane do potrzeb
                            </motion.h2>
                        </div>
                    </Container>
                </div>
            </section>
        );
    }
}

export default Banner;